import React from 'react';
import ReactDOM from 'react-dom/client';
// import $ from 'jquery';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LoadingPage from './components/widget/LoadingPage';
// 1. import `ChakraProvider` component
// import { ChakraProvider } from '@chakra-ui/react'




const App = React.lazy(()=>import('./App'));
// eslint-disable-next-line @typescript-eslint/no-unused-vars



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<LoadingPage/>}>
    <BrowserRouter>
    {/* <ChakraProvider> */}
      <App />
    {/* </ChakraProvider> */}
    </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
